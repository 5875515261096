import React, { useState, useEffect } from 'react';
import Swal from "sweetalert2";
import { useNavigate } from 'react-router-dom'
import '../styles/login.css'
import kioskbluelogo from '../assets/images/v3-kiosk-logo-blue.svg';
import { Container, Box } from '@mui/material';
import { Button, Grid, Typography } from '@mui/material';



//Azure 
import { useMsal } from "@azure/msal-react";
import { setCookie, getCookie } from '../components/Helper/cookies';
import { postLogoutRedirectUri, msalConfig, loginRequest } from '../config/config';


function Login() {

  // Azure
  const { instance } = useMsal();
  const [userStatus, setUserStatus] = useState({ isLoginError: false, isUserResetPassword: false });
  const navigate = useNavigate();


  const clearCookies = () => {
    setCookie("isLoginFailed", "");
    setCookie("isUserResetPassword", "");
    setUserStatus({ isLoginError: false, isUserSignUp: false, isUserResetPassword: false })
  }


  const handleAzureLogin = (msalIns) => {
    clearCookies();
    msalIns.loginRedirect(loginRequest).then().catch(e => {
      console.error(e);
    });
  }
  useEffect(() => {
    if (getCookie("isLoginFailed") === "true") {
      setUserStatus({ ...userStatus, isLoginError: true })
      const swalWithCustompButtons = Swal.mixin({
        customClass: {
            confirmButton: 'custom-ok-button',
        },
        buttonsStyling: false
    })
    swalWithCustompButtons.fire({
        title: "",
        text: 'Authentication (login or password) has failed. Please try again',
        showCancelButton: false,
        confirmButtonText: 'Ok',
    }).then((result) => {
      if (result.isConfirmed) {
      clearCookies();
    } 
    })
    }
    if (getCookie("isUserResetPassword") === "true") {
      setUserStatus({ ...userStatus, isUserResetPassword: true })
    }

    if (window.sessionStorage.getItem("isLogout") === "true"){
      sessionStorage.clear();
      localStorage.clear();
    }

    instance.addEventCallback(async (event) => {
      if (event.eventType === "msal:loginSuccess") {
        if (event?.payload?.idTokenClaims?.isForgotPassword) {
          setCookie("isUserResetPassword", true);
        //   azureLogOut(instance);
        if(window.sessionStorage.getItem('home_account')){
            const currentAccount =  instance.getAccountByHomeId(JSON.parse(window.sessionStorage.getItem('home_account')).homeAccountId);	
              instance.logoutRedirect({	
              account: currentAccount,	
              postLogoutRedirectUri: postLogoutRedirectUri.redirectUri,	
              authority: msalConfig.auth.authority	
            });	
          }else{
              instance.logoutRedirect({	
              postLogoutRedirectUri: postLogoutRedirectUri.redirectUri,	
              authority: msalConfig.auth.authority	
            });	
          }
          window.sessionStorage.setItem('isLogout',true);
        }
        const myAccount = instance.getAccountByHomeId(event.payload.account.homeAccountId);
        instance.setActiveAccount(myAccount);
        window.sessionStorage.setItem("home_account", JSON.stringify(myAccount));
        window.sessionStorage.setItem("user_name", event.payload.account.idTokenClaims.preferred_username.toLowerCase());
        window.sessionStorage.setItem("id_token", event.payload.idToken);
        const oauth = {
          token_type: "Bearer",
          access_token: event.payload.idToken
        }
        
        window.sessionStorage.setItem("oauthToken", JSON.stringify(oauth));
        navigate('/feedback/device/');

    
      }
      if (event.eventType === "msal:loginFailure" || event.eventType === "msal:acquireTokenFailure") {
        // azureLogOut(instance);
        if(window.sessionStorage.getItem('home_account')){
            const currentAccount =  instance.getAccountByHomeId(JSON.parse(window.sessionStorage.getItem('home_account')).homeAccountId);	
              instance.logoutRedirect({	
              account: currentAccount,	
              postLogoutRedirectUri: postLogoutRedirectUri.redirectUri,	
              authority: msalConfig.auth.authority	
            });	
          }else{
              instance.logoutRedirect({	
              postLogoutRedirectUri: postLogoutRedirectUri.redirectUri,	
              authority: msalConfig.auth.authority	
            });	
          }
          window.sessionStorage.setItem('isLogout',true);
      }

    }, error => {
      console.log('error', error);
    });

  }, [instance, userStatus,navigate])
 


  return (
    <>
     <Container maxWidth="md">
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '75vh' }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12} sx={{ textAlign: 'center' }}>
            <img src={kioskbluelogo} className="kiosk-logo-blue" alt="Kiosk blue logo" />
            <Typography variant="h6" sx={{ marginBottom: '10px' }}>iPad Monitoring System</Typography>
            <form>
              <Grid container justifyContent="center" alignItems="center">
                <Grid item xs={12} sm={6} md={4} sx={{ margin: '5px 10px' }}>
                  <Button
                    sx={{ padding: '15px 10px' }}
                    fullWidth variant="contained"
                    color="primary"
                    className='button-info'
                    onClick={() => { handleAzureLogin(instance) }}
                  >
                    Sign In
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </Box>
    </Container>
    </>
  )
}


export default Login
