import React, { useState } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import kioskWhitelogo from '../assets/images/v3-kiosk-logo-white.svg';
import { useMsal } from "@azure/msal-react";
import {postLogoutRedirectUri,msalConfig } from '../config/config';
import { AccountCircle,ExitToApp,ArrowDropDown  } from '@mui/icons-material';

const Header = () => {
  const deviceDetails = JSON.parse(window.sessionStorage.getItem("deviceDetails"));
  const { instance } = useMsal();
  const [anchorEl, setAnchorEl] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const name = JSON.parse(window.sessionStorage.getItem('home_account')).name;

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleUsernameClick = (event) => {
    if (!isMobile) {
      handleMenuOpen(event);
    }
  }

  const handleLogout = () => {
    handleMenuClose();
    window.sessionStorage.removeItem("userInfo");
    window.sessionStorage.removeItem("oauthToken");
    window.sessionStorage.removeItem("systemInfo");
    if (window.sessionStorage.getItem('home_account')) {
      const currentAccount = instance.getAccountByHomeId(JSON.parse(window.sessionStorage.getItem('home_account')).homeAccountId);
      instance.logoutRedirect({
        account: currentAccount,
        postLogoutRedirectUri: postLogoutRedirectUri.redirectUri,
        authority: msalConfig.auth.authority
      });
    } else {
      instance.logoutRedirect({
        postLogoutRedirectUri: postLogoutRedirectUri.redirectUri,
        authority: msalConfig.auth.authority
      });
    }
    window.location.href = '/feedback?deviceid='+deviceDetails.deviceId+"&deviceName="+deviceDetails.deviceName+"&aliasName="+deviceDetails.aliasName+"&locationName="+deviceDetails.locationName+"&communityCenterName="+deviceDetails.communityCenterName;
  };

  return (
    <AppBar position="static">
      <Toolbar sx={{backgroundColor: '#005486',padding:"8px"}}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
        <Typography variant="h5" component="h1" style={{ flexGrow: 1 }}>
          <img alt="dta" src={kioskWhitelogo} height="40" />
          <span style={{verticalAlign:'super'}}> iPad Monitoring System</span>
        </Typography>
        </div>
        
        {isMobile ? (
          // Mobile view
          <div style={{ marginLeft: 'auto' }}>
            <IconButton
              edge="end"
              color="inherit"
              aria-label="user-menu"
              onClick={handleMenuOpen}
            >
              <AccountCircle />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              <MenuItem onClick={handleMenuClose}>Welcome {name}</MenuItem>
              <MenuItem onClick={handleLogout}><ExitToApp /> Logout</MenuItem>
            </Menu>
          </div>
        ) : (
          // Desktop view
            <div style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}>
            <IconButton
              edge="end"
              color="inherit"
              aria-label="user-menu"
              onClick={handleMenuOpen}
              style={{ marginLeft: '10px' }}
            >
              <AccountCircle />
            </IconButton>
            <Typography onClick={handleUsernameClick}  sx={{cursor:"pointer",marginLeft:'10px'}} variant="body1">Welcome {name}</Typography>
            <ArrowDropDown  onClick={handleMenuOpen} />
            <Menu
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              <MenuItem onClick={handleLogout}><ExitToApp />Logout</MenuItem>
            </Menu>
          </div>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Header;
