import React from 'react';
import {Grid, Typography } from '@mui/material';
import moment from 'moment';

const DeviceInfo= (props) =>{
    const data = props.props;
    const lastLoginTime = moment(data.lastLoginTime).format('MM/DD/YYYY h:mm A') 

    return(
        <Grid container mt={3}>
        <Grid item lg={6} xs={5} fontWeight="600" >
          <Typography sx={{marginBottom:"5px"}}>Serial Number</Typography>
        </Grid>
        <Grid item lg={1} xs={2} fontWeight="600">
          <Typography>:</Typography>
        </Grid>
        <Grid item lg={5} xs={5} pr={0} textAlign="left">
          <Typography sx={{fontSize:"13px",marginBottom:"10px"}}>
            <span className="font-weight-normal" id="singleSerialNumber">
             {data.deviceName}
            </span>
          </Typography> 
        </Grid>
        <Grid item lg={6} xs={5} fontWeight="600" className="linkedSection deviceNameSection">
          <Typography sx={{fontSize:"13px",marginBottom:"10px"}}>Device Name</Typography>
        </Grid>
        <Grid item lg={1} xs={2} fontWeight="600" className="linkedSection deviceNameSection">
          <Typography>:</Typography>
        </Grid>
        <Grid item lg={5} xs={5} pr={0} textAlign="left" className="linkedSection deviceNameSection">
          <Typography sx={{fontSize:"13px",marginBottom:"10px"}}>
            <span className="font-weight-normal" id="singleDeviceName">
              {data.aliasName ? data.aliasName : "-"}
            </span>
          </Typography>
        </Grid>
        <Grid item lg={6} xs={5} fontWeight="600" className="linkedSection">
          <Typography sx={{fontSize:"13px",marginBottom:"10px"}}>Location Name</Typography>
        </Grid>
        <Grid item lg={1} xs={2} fontWeight="600" className="linkedSection">
          <Typography>:</Typography>
        </Grid>
        <Grid item lg={5} xs={5} pr={0} textAlign="left" className="linkedSection">
          <Typography sx={{fontSize:"13px",marginBottom:"10px"}}>
            <span className="font-weight-normal" id="singleLocationName">{data.locationName ? data.locationName : "-"}</span>
          </Typography>
        </Grid>
        {data.communityCenterName?
        <>
           <Grid item lg={6} xs={5} fontWeight="600" className="linkedSection communtiySection">
          <Typography sx={{fontSize:"13px",marginBottom:"10px"}}>Community Center Name</Typography>
        </Grid>
        <Grid item lg={1} xs={2} fontWeight="600" className="linkedSection communtiySection">
          <Typography>:</Typography>
        </Grid>
        <Grid item lg={5} xs={5} pr={0} textAlign="left" className="linkedSection communtiySection">
          <Typography sx={{fontSize:"13px",marginBottom:"10px"}}>
            <span className="font-weight-normal" id="singleCommunityCenter">{data.communityCenterName}</span>
          </Typography>
        </Grid>
        </> : null}
        <Grid item lg={6} xs={5} fontWeight="600">
          <Typography className="mb-0">Date</Typography>
        </Grid>
        <Grid item lg={1} xs={2} fontWeight="600">
          <Typography sx={{fontSize:"13px",marginBottom:"10px"}}>:</Typography>
        </Grid>
        <Grid item lg={5} xs={5} pr={0} textAlign="left">
          <Typography className="mb-0">
            <span className="font-weight-normal">{lastLoginTime}</span>
          </Typography>
        </Grid>
      </Grid>
    )
}

export default DeviceInfo;