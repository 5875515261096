import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { createStore,applyMiddleware  } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';

// Azure
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./config/config";

import rootReducer from './store/reducers';
import './index.css';
import App from './App';


import ErrorBoundary from './components/ErrorBoundary';

const store = createStore(rootReducer,applyMiddleware(thunk));


// Azure
const msalInstance = new PublicClientApplication(msalConfig);
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Provider store={store}>
    <BrowserRouter>
    <ErrorBoundary>
    <MsalProvider instance={msalInstance}>
    <App/>
    </MsalProvider>
    </ErrorBoundary>
    </BrowserRouter>
  </Provider>,
);
