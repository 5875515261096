import React from "react";
import {Routes,Route,Navigate } from "react-router-dom";
import SignIn from "../pages/SignIn";
import FeedBack from "../pages/feedback";



const AppRoutes =  () => {
    return (
      <Routes>
         <Route path="/" element={<Navigate to="/feedback" />} />
        <Route  path="/feedback" element={<SignIn />} />
        <Route path="/feedback/device" element={<FeedBack />} />
      </Routes>
    )
};


export default AppRoutes;
















