import React, { useEffect } from 'react';
import Bowser from "bowser";
import axios from 'axios';
import AppRoutes from './routes';

const App = () => {
  let systemInfo = {
    ip: "",
    deviceId: ""
  }

  useEffect(()=>{
    const urlParams = new URLSearchParams(window.location.search);
    const deviceDetails = {
      deviceId : urlParams.get('deviceid'),
      deviceName : urlParams.get('deviceName'),
      aliasName : urlParams.get('aliasName'),
      locationName : urlParams.get('locationName'),
      communityCenterName : urlParams.get('communityCenterName')
    }
    if(urlParams.get('deviceid') !== null || urlParams.get('deviceName') !== null){
      window.sessionStorage.setItem('deviceDetails', JSON.stringify(deviceDetails));
    }
  },[])

 
  useEffect(() => {
  const browser = Bowser.getParser(navigator.userAgent);
    if (browser) {
      systemInfo.deviceId = browser.getPlatform().type + " - " + browser.getBrowser().name + " " + browser.getBrowser().version + " - " + browser.getOS().name + " " + browser.getOS().version || '';
    }
    axios.get('https://api.ipify.org')
      .then((response) => {
        systemInfo.ip = response.data;
        sessionStorage.setItem('systemInfo', JSON.stringify(systemInfo));
      }).catch((error) => {
        console.log(error);
        systemInfo.ip = ''
        sessionStorage.setItem('systemInfo', JSON.stringify(systemInfo));
      });
  })

  return (

    <AppRoutes />

  );
}

export default App;
