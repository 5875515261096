// onshore
//QA
//export const appHomeUrl = "https://dta-kiosk-feedback-qa.ehs.mass.gov";
//PROD
export const appHomeUrl = "https://dta-kiosk-feedback.ehs.mass.gov";
//local
 //export const appHomeUrl = "http://localhost:3000";

export const msalConfig = {
    auth: {
        //
        clientId: "7319b7b5-63d5-45be-a6f6-16e5d038e2a7",
        authority: "https://login.microsoftonline.com/3e861d16-48b7-4a0e-9806-8c04d81b7b2a",
        knownAuthorities: ["massgov.onmicrosoft.com"],
        redirectUri: appHomeUrl+'/feedback/device',
        postLogoutRedirectUri: appHomeUrl,
        // response_type: "id_token",
        // prompt: "login",
    }
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
    scopes: ['openid', 'profile', 'user.read', 'email'],
    extraQueryParameters: {},
    authority: "https://login.microsoftonline.com/3e861d16-48b7-4a0e-9806-8c04d81b7b2a",
};


export const postLogoutRedirectUri = {
    redirectUri: `${appHomeUrl}`
}

export const config = {
    //======================================================================================
    //Onshore-QA
    //'API_URL': "https://moyp604ly7.execute-api.us-east-1.amazonaws.com/qa/feedback",
    //======================================================================================
    
    //++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
    //Onshore-PROD
    'API_URL': "https://sh407pbe17.execute-api.us-east-1.amazonaws.com/prod/feedback",
    //++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

    //offshore
    //'API_URL': "https://4zohk11zk3.execute-api.us-east-1.amazonaws.com/dev/feedback",
}

export const origin = "*"
