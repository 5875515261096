import * as actionTypes from '../../store/actions/actionTypes';

const initState = {
  isAuth:false,
  loading:false,
  isError:false,
  userDetails:{},
  registerDetails: {},
  forgotDetails: {},
  verifyCodeDetails: {},
  signOutDetails: {},
  errorMessage: {},
  isLoginSuccessfull:false,
  loginDetails:{}
}

const authReducer = (state = initState, action = {}) => {
  const newState = {...state};

    switch (action.type) {
        case actionTypes.AZURE_SIGN_IN:
          return {...newState , isLoading: true};

        case actionTypes.AZURE_SIGN_IN_SUCCESS:
          window.sessionStorage.setItem("userInfo", JSON.stringify(action.payload));
          return {...newState , isLoading: false, isError:true, isLoginSuccessfull:true, loginDetails: {...action.payload} };
    
          case actionTypes.AZURE_SIGN_IN_FAILURE:
            return {...newState , isLoading: false, isError:true, isLoginSuccessfull:false, errorMessage: {...action.payload} };
        

      default:
        return state;
    }
  };
  export default authReducer;