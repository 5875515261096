import React, { useState } from 'react';
import { Box, Grid, Typography, Switch, Button,Container } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Header from './Header';
import DeviceInfo from './deviceInfo'
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import axios from 'axios';
import { config } from '../config/config'
import swal from "sweetalert";


const theme = createTheme({
  typography: {
    fontSize: 12,
  },
});
const GetFeedback = () => {
  const [open, setOpen] = useState(false);
  const [isButton, setisButton] = useState(true);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const handleChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const swalAlertAuto = (type, msg) => {
    var typeTxt = "";
    if (type === "success") {
      typeTxt = "Success";
    } else if (type === "error") {
      typeTxt = "Failed";
    }
    swal({
      icon: type,
      type: type,
      title: typeTxt,
      text: msg,
      showConfirmButton: false,
      timer: 2000,
      button: false
    });
  };


  const [switches, setSwitches] = useState({
    isIpadCharged: true,
    isIpadWorkingProperly: true,
    isAllIconsWorking: true,
    isConnectedToWifi: true,
    isKioskVisible: true,
    isJobGuideVisible: true,
    isClientsUsingKiosk: true,
    isChargerNeeded: true,
    isDtaMaterialsNeeded: true,
    isIpadNeedToBeReplaced: true,
    isIpadBeingRemoved: true,
  });

  const handleSwitchToggle = (name) => {
    setSwitches((prevState) => ({
      ...prevState,
      [name]: !prevState[name],
    }));
  };
  const handleButtonClick = () => {
    const selectedSwitches = Object.entries(switches)
      .filter(([name, isSelected]) => isSelected)
      .map(([name]) => name);
    const feedbackQuestion = [
      "isIpadCharged",
      "isIpadWorkingProperly",
      "isAllIconsWorking",
      "isConnectedToWifi",
      "isKioskVisible",
      "isJobGuideVisible",
      "isClientsUsingKiosk",
      "isChargerNeeded",
      "isDtaMaterialsNeeded",
      "isIpadNeedToBeReplaced",
      "isIpadBeingRemoved",
    ]
    const missingValue = feedbackQuestion.filter(x => selectedSwitches.indexOf(x) === -1);
    var payload = new FormData();

    feedbackQuestion.forEach(item => {
      if (missingValue.includes(item)) {
        payload.append(item, false)
      } else {
        payload.append(item, true)

      }
    });


    const username = window.sessionStorage.getItem("user_name")
    const deviceDetails = JSON.parse(window.sessionStorage.getItem("deviceDetails"));

    payload.append('deviceId', Number(deviceDetails.deviceId))
    payload.append('submittedBy', username || "")
    payload.append('comments', (inputValue ? inputValue : ''))
    
    var queryParams = {
      method: 'POST',
      url: `${config.API_URL}`,
      headers: {
        'accept': 'application/json',
        'Content-Type': 'application/json'
      },
      data:payload
    };
    axios(queryParams).then(function (response) {
      const responseObj = response.data.SendMessageResponse;
      if(responseObj.SendMessageResult?.MessageId){
        setOpen(false);
        setisButton(false);
        setButtonClicked(true);
        swalAlertAuto("success", "Device details submitted successfully!");
      }else{
        swalAlertAuto("error", " Error in Device details submission");
      }


    })
      .catch(function (error) {
        swalAlertAuto("error", " Error in Device details submission");
        console.log(error)
      })
  };


  const getdeviceInfo = window.sessionStorage.getItem('deviceDetails')
  const deviceDetails = JSON.parse(getdeviceInfo)
  const auth = JSON.parse(window.sessionStorage.getItem('oauthToken'))
  if(auth){
    return (
      <>
        <Header></Header>
        <Container maxWidth="xs">
      <Box sx={{margin: '10px 10px 10px' }}>
          <ThemeProvider theme={theme}>
            {deviceDetails && <DeviceInfo props={deviceDetails} /> }
            <hr />
            <Grid container mt={3}>
              <Grid item lg={7} xs={7} fontWeight="600" >
                <Typography sx={{ marginTop: "7px" }}>
                  <span style={{ verticalAlign: "sub" }}>IPAD Charged</span>
                </Typography>
              </Grid>
  
              <Grid item lg={5} xs={5} pr={0} textAlign="right">
                <Typography sx={{ fontSize: "13px", }}>
                  <Switch
                    checked={switches.isIpadCharged}
                    onChange={() => handleSwitchToggle('isIpadCharged')}
                    disabled={buttonClicked}
                  />
                </Typography>
              </Grid>
  
              <Grid item lg={6} xs={5} fontWeight="600" >
                <Typography sx={{ marginTop: "7px" }}>
                  <span style={{ verticalAlign: "sub" }}>IPAD Working Properly</span>
                </Typography>
              </Grid>
              <Grid item lg={1} xs={2} fontWeight="600">
                <Typography></Typography>
              </Grid>
              <Grid item lg={5} xs={5} pr={0} textAlign="right">
                <Typography sx={{ fontSize: "13px", }}>
                  <Switch
                    checked={switches.isIpadWorkingProperly}
                    onChange={() => handleSwitchToggle('isIpadWorkingProperly')}
                    disabled={buttonClicked}
                  />
                </Typography>
              </Grid>
  
              <Grid item lg={6} xs={5} fontWeight="600" >
                <Typography sx={{ marginTop: "7px" }}>
                  <span style={{ verticalAlign: "sub" }}>All Icons Working</span>
                </Typography>
              </Grid>
              <Grid item lg={1} xs={2} fontWeight="600">
                <Typography></Typography>
              </Grid>
              <Grid item lg={5} xs={5} pr={0} textAlign="right">
                <Typography sx={{ fontSize: "13px", }}>
                  <Switch
                    checked={switches.isAllIconsWorking}
                    onChange={() => handleSwitchToggle('isAllIconsWorking')}
                    disabled={buttonClicked}
                  />
                </Typography>
              </Grid>
  
              <Grid item lg={6} xs={5} fontWeight="600" >
                <Typography sx={{ marginTop: "7px" }}>
                  <span style={{ verticalAlign: "sub" }}>Connected to Wi-Fi</span>
                </Typography>
              </Grid>
              <Grid item lg={1} xs={2} fontWeight="600">
                <Typography></Typography>
              </Grid>
              <Grid item lg={5} xs={5} pr={0} textAlign="right">
                <Typography sx={{ fontSize: "13px", }}>
                  <Switch
                    checked={switches.isConnectedToWifi}
                    onChange={() => handleSwitchToggle('isConnectedToWifi')}
                    disabled={buttonClicked}
                  />
                </Typography>
              </Grid>
  
              <Grid item lg={6} xs={5} fontWeight="600" >
                <Typography sx={{ marginTop: "7px" }}>
                  <span style={{ verticalAlign: "sub" }}>Kiosk Visible</span>
                </Typography>
              </Grid>
              <Grid item lg={1} xs={2} fontWeight="600">
                <Typography></Typography>
              </Grid>
              <Grid item lg={5} xs={5} pr={0} textAlign="right">
                <Typography sx={{ fontSize: "13px", }}>
                  <Switch
                    checked={switches.isKioskVisible}
                    onChange={() => handleSwitchToggle('isKioskVisible')}
                    disabled={buttonClicked}
                  />
                </Typography>
              </Grid>
  
              <Grid item lg={6} xs={5} fontWeight="600" >
                <Typography sx={{ marginTop: "7px" }}>
                  <span style={{ verticalAlign: "sub" }}>Job Guide Visible</span>
                </Typography>
              </Grid>
              <Grid item lg={1} xs={2} fontWeight="600">
                <Typography></Typography>
              </Grid>
              <Grid item lg={5} xs={5} pr={0} textAlign="right">
                <Typography sx={{ fontSize: "13px", }}>
                  <Switch
                    checked={switches.isJobGuideVisible}
                    onChange={() => handleSwitchToggle('isJobGuideVisible')}
                    disabled={buttonClicked}
                  />
                </Typography>
              </Grid>
  
              <Grid item lg={6} xs={5} fontWeight="600" >
                <Typography sx={{ marginTop: "7px" }}>
                  <span style={{ verticalAlign: "sub" }}>Clients Using Kiosk</span>
                </Typography>
              </Grid>
              <Grid item lg={1} xs={2} fontWeight="600">
                <Typography></Typography>
              </Grid>
              <Grid item lg={5} xs={5} pr={0} textAlign="right">
                <Typography sx={{ fontSize: "13px", }}>
                  <Switch
                    checked={switches.isClientsUsingKiosk}
                    onChange={() => handleSwitchToggle('isClientsUsingKiosk')}
                    disabled={buttonClicked}
                  />
                </Typography>
              </Grid>
  
              <Grid item lg={6} xs={5} fontWeight="600" >
                <Typography sx={{ marginTop: "7px" }}>
                  <span style={{ verticalAlign: "sub" }}>Charger Needed</span>
                </Typography>
              </Grid>
              <Grid item lg={1} xs={2} fontWeight="600">
                <Typography></Typography>
              </Grid>
              <Grid item lg={5} xs={5} pr={0} textAlign="right">
                <Typography sx={{ fontSize: "13px", }}>
                  <Switch
                    checked={switches.isChargerNeeded}
                    onChange={() => handleSwitchToggle('isChargerNeeded')}
                    disabled={buttonClicked}
                  />
                </Typography>
              </Grid>
  
              <Grid item lg={6} xs={5} fontWeight="600" >
                <Typography sx={{ marginTop: "7px" }}>
                  <span style={{ verticalAlign: "sub" }}>DTA Materials Needed</span>
                </Typography>
              </Grid>
              <Grid item lg={1} xs={2} fontWeight="600">
                <Typography></Typography>
              </Grid>
              <Grid item lg={5} xs={5} pr={0} textAlign="right">
                <Typography sx={{ fontSize: "13px", }}>
                  <Switch
                    checked={switches.isDtaMaterialsNeeded}
                    onChange={() => handleSwitchToggle('isDtaMaterialsNeeded')}
                    disabled={buttonClicked}
                  />
                </Typography>
              </Grid>
  
              <Grid item lg={6} xs={5} fontWeight="600" >
                <Typography sx={{ marginTop: "7px" }}>
                  <span style={{ verticalAlign: "sub" }}>IPAD need to be replaced</span>
                </Typography>
              </Grid>
              <Grid item lg={1} xs={2} fontWeight="600">
                <Typography></Typography>
              </Grid>
              <Grid item lg={5} xs={5} pr={0} textAlign="right">
                <Typography sx={{ fontSize: "13px", }}>
                  <Switch
                    checked={switches.isIpadNeedToBeReplaced}
                    onChange={() => handleSwitchToggle('isIpadNeedToBeReplaced')}
                    disabled={buttonClicked}
                  />
                </Typography>
              </Grid>
  
              <Grid item lg={6} xs={5} fontWeight="600" >
                <Typography sx={{ marginTop: "7px" }}>
                  <span style={{ verticalAlign: "sub" }}>IPAD being removed</span>
                </Typography>
              </Grid>
              <Grid item lg={1} xs={2} fontWeight="600">
                <Typography></Typography>
              </Grid>
              <Grid item lg={5} xs={5} pr={0} textAlign="right">
                <Typography sx={{ fontSize: "13px", }}>
                  <Switch
                    checked={switches.isIpadBeingRemoved}
                    onChange={() => handleSwitchToggle('isIpadBeingRemoved')}
                    disabled={buttonClicked}
                  />
                </Typography>
              </Grid>
  
            </Grid>
            <Grid item xs={12} sm={6} md={4} sx={{ margin: '15px 10px' }}>
              {isButton && (
                <Button sx={{ padding: '15px 10px' }} fullWidth variant="contained" color="primary" className='button-info' onClick={handleClickOpen}>
                  Submit
                </Button>)}
            </Grid>
            <Dialog fullWidth open={open} onClose={handleClose}>
              <DialogTitle>Confirmation</DialogTitle>
              <DialogContent>
                <TextField
                  multiline
                  maxRows={4}
                  autoFocus
                  margin="dense"
                  id="name"
                  label="Comments"
                  fullWidth
                  value={inputValue}
                  onChange={handleChange}
                />
  
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={handleButtonClick}>Submit</Button>
              </DialogActions>
            </Dialog>
          </ThemeProvider>
        </Box>
      </Container>
      </>
    );
  }
};

export default GetFeedback;

