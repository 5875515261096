import { combineReducers } from 'redux';
import usersReducer from './userReducer';
import authReducer from './authReducers';


const rootReducer = combineReducers({
  data: usersReducer,
  auth: authReducer,

})

export default rootReducer;
